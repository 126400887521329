import axios from 'axios';
const base_url = 'http://localhost:5000'




export const getData = async (route, params) => {
  const config = {
    headers: {
      "authorization": `Bearer ${localStorage.getItem('token')}`,
      "Content-Type": "application/json",
    }
  };
  try {
    const response = await axios.get(`${base_url}${route}${params}`, config);
    response.ok = response.status >= 200 && response.status < 300;
    return response;
  } catch (error) {
    if (error.response.status === 403) {
      //alert('token is not valid')
      localStorage.removeItem('token');
      //localStorage.removeItem('isAdmin');
      window.location.reload()
    }
    console.error('error in call:', error);
    error.response.ok = false;
    return error.response
  }
}



export const postData = async (route, obj) => {
  const config = {
    headers: {
      "authorization": `Bearer ${localStorage.getItem('token')}`,
      "Content-Type": "application/json",
    }
  };  
  try {
    const response = await axios.post(base_url + route, obj, config);
    response.ok = response.status >= 200 && response.status < 300;
  
    return response;
  } catch (error) {
    console.error('error in call:', error);
    error.response.ok = false;
    return error.response
  }
}

export const postDataFile = async (route, obj) => {
  const config = {
    headers: {
      "authorization": `Bearer ${localStorage.getItem('token')}`,
      "Content-Type": "multipart/form-data",
    }
  };  
  try {
    const response = await axios.post(base_url + route, obj, config);
    response.ok = response.status >= 200 && response.status < 300;
    return response;
  } catch (error) {
    console.error('error in call:', error);
    error.response.ok = false;
    return error.response
  }
}

export const putData = async (route, obj) => {
  const config = {
    headers: {
      "authorization": `Bearer ${localStorage.getItem('token')}`,
      "Content-Type": "application/json",
    }
  };  
  try {
    const response = await axios.put(base_url + route, obj, config);
    response.ok = response.status >= 200 && response.status < 300;
    return response;
  } catch (error) {
    console.error('error in call:', error);
    error.response.ok = false;
    return error.response
  }
}


export const delData = async (route) => {
  const config = {
    headers: {
      "authorization": `Bearer ${localStorage.getItem('token')}`,
      "Content-Type": "application/json",
    }
  };
  try {
    const response = await axios.delete(`${base_url}${route}`, config);
    response.ok = response.status >= 200 && response.status < 300;
    return response;
  } catch (error) {
    if (error.response.status === 403) {
      //alert('token is not valid')
      localStorage.removeItem('token');
      //localStorage.removeItem('isAdmin');
      window.location.reload()
    }
    console.error('error in call:', error);
    error.response.ok = false;
    return error.response
  }
}