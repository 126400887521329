import { useState } from "react";
import { FaBtc } from "react-icons/fa";
import { useNavigate } from "react-router-dom";



const PolicyPage = () => {
    const navigate = useNavigate()
    const [isScrolled, setIsScrolled] = useState(false);
    


    return (
        <div className="flex flex-col min-h-screen">


        {/* MENU */}
        <div className={`fixed ${true ? 'shadow' : 'bg-white/80 '} backdrop-blur-sm top-0 w-full transition duration-300 ease-in-out z-20`}
            style={{background: true ? 'linear-gradient(83.51deg, #6A47C9 20.66%, rgba(166, 133, 246, 0.9551) 48.11%, rgba(94, 224, 212, 0.5838) 92.73%)' : ''}}
        >
            <div className='bod min-h-[72px] flex flex-row items-center justify-between'>
                <div onClick={() => navigate('/')} className={`cursor-pointer text-2xl font-semibold ${true ? 'text-white' : 'text-king'} flex flex-row space-x-1`}>
                    {/* <FaBtc className="text-3xl" /> */}
                    <span>BsmartSolutions</span>
                    <span className='text-sm relative font-bold'>LTD</span>
                </div>
                <div className='flex flex-row items-center space-x-6'>
                </div>
            </div>
        </div>



            <div>
                {/* SEC1 */}
                <div>
                    <div className='bod py-[120px] space-y-10'>
                        <h1 className='text-king text-[32px] font-bold'>Privacy Policy</h1>

                        <div className="space-y-2">
                            <h2 className="text-king text-[22px] font-bold">
                            Introduction
                            </h2>
                            <p className="text-lg text-gray-600 font-semibold max-w-[1000px]">
                            This statement should be read combined with all policies shown on this Website, which set out the rules and expectations for how Our Company does business in compliance with all applicable laws of the countries in which we operate.
                            </p>
                        </div>

                        <div className="space-y-2">
                            <h2 className="text-king text-[22px] font-bold">
                            About The Company
                            </h2>
                            <p className="text-lg text-gray-600 font-semibold max-w-[1000px]">
                            Our Company  has been in operation since August 2017, and is a law-abiding company which counsels & helps victims of fraud to recover their stolen funds.

                            </p>
                        </div>

                        <div className="space-y-2">
                            <h2 className="text-king text-[22px] font-bold">
                            The Services
                            </h2>
                            <p className="text-lg text-gray-600 font-semibold max-w-[1000px]">
                            Our Company helps consumers, who approach us for advice and guidance.

The services are provided by and/or are under the supervision of, competent attorneys who are Israeli-licensed attorneys admitted to the Israel Bar Association. The services include recovery consulting, which constitutes assisting our clients through the procedure of cancelation and refund of transactions using an out-of-court alternative dispute resolution process. The company does not represent its clients and is not obligated to appear in any litigation, arbitration, or administrative proceedings, the services rendered do not include court representation.

Furthermore, Our Company cannot guarantee the outcome or result in connection with the recovery consulting services that consumers retain.Our Company offers consumers a free consultation

Recovery consulting services will be subsequently commissioned and will incur fees and/or commissions, based on the service and the complexity of each individual case, under contract.

                            </p>
                        </div>

                        <div className="space-y-2">
                            <h2 className="text-king text-[22px] font-bold">
                            Compliance, Regulation & Authorization
                            </h2>
                            <p className="text-lg text-gray-600 font-semibold max-w-[1000px]">
                            Our Company, is authorized and regulated under the Israeli law and operates globally. Our Company  activity is authorized and in full compliance with any regulatory requirements of the jurisdictions in which it operates.

                            </p>
                        </div>

                        <div className="space-y-2">
                            <h2 className="text-king text-[22px] font-bold">
                            No Warranties
                            </h2>
                            <p className="text-lg text-gray-600 font-semibold max-w-[1000px]">
                            Our Company  performs the services rendered to consumers, who approach us for advice and counsel them to the best of our knowledge and understanding, based on our experience. The company does not represent its clients and cannot make and express or implied warranties of any kind about any outcome or result in connection to the recovery consulting services that consumers retain.

                            </p>
                        </div>

                        <div className="space-y-2">
                            <h2 className="text-king text-[22px] font-bold">
                            Liability
                            </h2>
                            <p className="text-lg text-gray-600 font-semibold max-w-[1000px]">
                            Our Company, including its officers, directors, and employees, shall not be liable for any direct or indirect, consequential, or special liability arising out of or in any way related to the services, rather under the contract. By engaging the service, the client knowingly accepts and takes the responsibility upon himself/herself and acts for himself/herself under the services rendered by the company.

                            </p>
                        </div>

                    </div>
                </div>
            </div>    
        </div>
    )
}   


export default PolicyPage