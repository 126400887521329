import { useState } from "react";
import { FaBtc } from "react-icons/fa";
import { useNavigate } from "react-router-dom";



const TermsPage = () => {
    const navigate = useNavigate()
    const [isScrolled, setIsScrolled] = useState(false);
    


    return (
        <div className="flex flex-col min-h-screen">


        {/* MENU */}
        <div className={`fixed ${true ? 'shadow' : 'bg-white/80 '} backdrop-blur-sm top-0 w-full transition duration-300 ease-in-out z-20`}
            style={{background: true ? 'linear-gradient(83.51deg, #6A47C9 20.66%, rgba(166, 133, 246, 0.9551) 48.11%, rgba(94, 224, 212, 0.5838) 92.73%)' : ''}}
        >
            <div className='bod min-h-[72px] flex flex-row items-center justify-between'>
                <div onClick={() => navigate('/')} className={`cursor-pointer text-2xl font-semibold ${true ? 'text-white' : 'text-king'} flex flex-row space-x-1`}>
                    {/* <FaBtc className="text-3xl" /> */}
                    <span>BsmartSolutions</span>
                    <span className='text-sm relative font-bold'>LTD</span>
                </div>
                <div className='flex flex-row items-center space-x-6'>
                    {/* <div className={`flex-row space-x-8 font-semibold text-lg ${true ? 'text-white' : ''} hidden md:flex`}>
                    </div>
                    <button className={`btn3 py-2  font-semibold ${true ? 'bg-king text-white hover:bg-[#6A47C9]' : 'bg-[#6A47C9] hover:bg-king'}`}>
                        Get your money back
                    </button> */}
                </div>
            </div>
        </div>



            <div>
                {/* SEC1 */}
                <div>
                    <div className='bod py-[120px] space-y-10'>
                        <h1 className='text-king text-[32px] font-bold'>Terms & Conditions</h1>


                        <div className="space-y-2">
                            <h2 className="text-king text-[22px] font-bold">
                            Introduction
                            </h2>
                            <p className="text-lg text-gray-600 font-semibold max-w-[1000px]">
                            These Website Standard Terms And Conditions (these “Terms” or these “Website Standard Terms And Conditions”) contained herein on this webpage, shall govern your use of this website, including all pages within this website (collectively referred to herein below as this “Website”). These Terms apply in full force and effect to your use of this Website and by using this Website, you expressly accept all terms and conditions contained herein in full. You must not use this Website, if you have any objection to any of these Website Standard Terms And Conditions.

This Website is not for use by any minors (defined as those who are not at least 18 years of age), and you must not use this Website if you a minor.

                            </p>
                        </div>

                        <div className="space-y-2">
                            <h2 className="text-king text-[22px] font-bold">
                            Link to Other websites
                            </h2>
                            <p className="text-lg text-gray-600 font-semibold max-w-[1000px]">
                            Our Service may contain links to third-party web sites or services that are not owned or controlled.

We don't have no control over, and assumes no responsibility for, the content, privacy policies, or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or practices of any third party web sites or services. You further acknowledge and agree that Our company shall not be responsible or services available on or through any such.

                            </p>
                        </div>

                        <div className="space-y-2">
                            <h2 className="text-king text-[22px] font-bold">
                            The Services
                            </h2>
                            <p className="text-lg text-gray-600 font-semibold max-w-[1000px]">
                            provides advice and guidance. The services are provided by and/or under the supervision of competent attorneys who are Israeli-licensed attorneys admitted to the Israel Bar Association. The services include recovery consulting, which constitutes assisting our clients through the procedure of cancelation and refund of transactions using an out-of-court alternative dispute resolution process. The company does not represent its clients. You can visit our Contact Us page for more information.
                            </p>
                        </div>

                        <div className="space-y-2">
                            <h2 className="text-king text-[22px] font-bold">
                            Intellectual Property Rights
                            </h2>
                            <p className="text-lg text-gray-600 font-semibold max-w-[1000px]">
                            Other than the content you own, which you may have opted to include on this Website, under these Terms,  its licensors own all rights to the intellectual property and material contained in this Website, and all such rights are reserved.

You are granted a limited license only, subject to the restrictions provided in these Terms, for purposes of viewing the material contained on this Website.

                            </p>
                        </div>

                        <div className="space-y-2">
                            <h2 className="text-king text-[22px] font-bold">
                            Restrictions
                            </h2>
                            <p className="text-lg text-gray-600 font-semibold max-w-[1000px]">
                            You are expressly and emphatically restricted from all of the following:

publishing any Website material in any media;
selling, sublicensing and/or otherwise commercializing any Website material;
publicly performing and/or showing any Website material;
using this Website in any way that is, or may be, damaging to this Website;
using this Website in any way that impacts user access to this Website;
using this Website contrary to applicable laws and regulations, or in a way that causes, or may cause, harm to the Website, or to any person or business entity;
engaging in any data mining, data harvesting, data extracting or any other similar activity in relation to this Website, or while using this Website;
using this Website to engage in any advertising or marketing;
Certain areas of this Website are restricted from access by you may further restrict access by you to any areas of this Website, at any time, in its sole and absolute discretion. Any user ID and password you may have for this Website are confidential and you must maintain confidentiality of such information.

                            </p>
                        </div>

                        <div className="space-y-2">
                            <h2 className="text-king text-[22px] font-bold">
                            Your Content
                            </h2>
                            <p className="text-lg text-gray-600 font-semibold max-w-[1000px]">
                            In these Website Standard Terms And Conditions, “Your Content” shall mean any audio, video, text, images, personal information or other material you choose to display on this Website. With respect to Your Content, by displaying it, you a non-exclusive, worldwide, irrevocable, royalty-free, sublicensable license to use, reproduce, adapt, publish, translate and distribute it in any and all media.

Your Content must be your own and must not be infringing on any third party’s rights. Our Company reserves the right to remove any of Your Content from this Website at any time, and for any reason, without notice.

                            </p>
                        </div>

                        <div className="space-y-2">
                            <h2 className="text-king text-[22px] font-bold">
                            No warranties
                            </h2>
                            <p className="text-lg text-gray-600 font-semibold max-w-[1000px]">
                            This Website is provided “as is” with all faults, and Our Company makes no express or implied representations or warranties, of any kind related to this Website or the materials contained on this Website. Additionally, nothing contained on this Website shall be construed as providing consult or advice to you.

                            </p>
                        </div>

                        <div className="space-y-2">
                            <h2 className="text-king text-[22px] font-bold">
                            Limitation of liability
                            </h2>
                            <p className="text-lg text-gray-600 font-semibold max-w-[1000px]">
                            In no event shall Our Company, nor any of its officers, directors and employees, be liable to you for anything arising out of or in any way connected with your use of this Website, whether such liability is under contract, tort or otherwise, and Our Company, including its officers, directors and employees shall not be liable for any indirect, consequential or special liability arising out of or in any way related to your use of this Website.

                            </p>
                        </div>

                        <div className="space-y-2">
                            <h2 className="text-king text-[22px] font-bold">
                            Indemnification
                            </h2>
                            <p className="text-lg text-gray-600 font-semibold max-w-[1000px]">
                            You hereby indemnify to the fullest extentOur Company from and against any and all liabilities, costs, demands, causes of action, damages and expenses (including reasonable attorney’s fees) arising out of or in any way related to your breach of any of the provisions of these Terms.

                            </p>
                        </div>

                        <div className="space-y-2">
                            <h2 className="text-king text-[22px] font-bold">
                            Severability
                            </h2>
                            <p className="text-lg text-gray-600 font-semibold max-w-[1000px]">
                            If any provision of these Terms is found to be unenforceable or invalid under any applicable law, such unenforceability or invalidity shall not render these Terms unenforceable or invalid as a whole, and such provisions shall be deleted without affecting the remaining provisions herein.

                            </p>
                        </div>

                        <div className="space-y-2">
                            <h2 className="text-king text-[22px] font-bold">
                            Variation of Terms
                            </h2>
                            <p className="text-lg text-gray-600 font-semibold max-w-[1000px]">
                            Our Company is permitted to revise these Terms at any time as it sees fit, and by using this Website you are expected to review such Terms on a regular basis to ensure you understand all terms and conditions governing use of this Website.

                            </p>
                        </div>

                        <div className="space-y-2">
                            <h2 className="text-king text-[22px] font-bold">
                            Assignment
                            </h2>
                            <p className="text-lg text-gray-600 font-semibold max-w-[1000px]">
                            Our Company shall be permitted to assign, transfer, and subcontract its rights and/or obligations under these Terms without any notification or consent required. However, .you shall not be permitted to assign, transfer, or subcontract any of your rights and/or obligations under these Terms.

                            </p>
                        </div>

                        <div className="space-y-2">
                            <h2 className="text-king text-[22px] font-bold">
                            Entire Agreement
                            </h2>
                            <p className="text-lg text-gray-600 font-semibold max-w-[1000px]">
                            These Terms, including any legal notices and disclaimers contained on this Website, constitute the entire agreement between Our Company and you in relation to your use of this Website, and supersede all prior agreements and understandings with respect to the same.

                            </p>
                        </div>

                        <div className="space-y-2">
                            <h2 className="text-king text-[22px] font-bold">
                            The cost and fees
                            </h2>
                            <p className="text-lg text-gray-600 font-semibold max-w-[1000px]">
                            The services provided by Our Company are conditioned by the client’s signature on the service agreement. The fixed-fee paid for services rendered in the relevant engagement with each customer, is not refundable and is not conditioned by the results. Cancellation of the services under the contract is in accordance with the Israeli Consumer Protection Law (Transaction Cancellation), 2010, S.2(3), within the first 14 business days of the process prior to receiving the services.

                            </p>
                        </div>

                        <div className="space-y-2">
                            <h2 className="text-king text-[22px] font-bold">
                            Governing Law & Jurisdiction
                            </h2>
                            <p className="text-lg text-gray-600 font-semibold max-w-[1000px]">
                            These Terms will be governed by and construed in accordance with the laws of the State of Israel, and you submit to the exclusive jurisdiction of the state and federal courts located in Israel for the resolution of any disputes.
                            </p>
                        </div>


                    </div>
                </div>
            </div>    
        </div>
    )
}   


export default TermsPage