import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate  } from 'react-router-dom';
import { AuthProvider, useAuth } from './lib/AuthContext';

import Landing from './pages/lands/Landing4'; // 2 4
import TermsPage from './pages/terms/Terms'

import { useEffect } from 'react';
import LoadingPage from './pages/general/LoadingPage';
import { useRecoilState } from 'recoil';
import { notiList0, messages0, currentUser0 } from './lib/states';
import PolicyPage from './pages/terms/Policy';


function App() {
  const [notiList, setNotiList] = useRecoilState(notiList0)

  useEffect(() => {
    const timer = setTimeout(() => {
      setNotiList(prevValue => prevValue.slice(1))
    }, 5000);
    return () => clearTimeout(timer);
  }, [notiList])

  useEffect(() => {
    document.title = "BsmartSolutions";
  }, []);


  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" 
            element={<AuthRoute restricted={true} component={<Landing />} />} />


          <Route path="/terms" 
            element={<AuthRoute restricted={true} component={<TermsPage />} />} />

          <Route path="/policy" 
            element={<AuthRoute restricted={true} component={<PolicyPage />} />} />
  

          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}


const AuthRoute = ({ component, restricted }) => {
  const { isLoggedIn } = useAuth();
  const { isAdmin } = useAuth();
  if (isLoggedIn) {
    if (isAdmin) return <Navigate to="/home" /> // /panel
    else return <Navigate to="/home" />
  } else if (!isLoggedIn) {
    return component
  } else {
    return <LoadingPage />
  }

};


export default App;
