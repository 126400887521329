import React, { useState, useEffect, useRef } from 'react';
import { FaSquare } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import { FaBtc } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";
import { FaChevronUp } from "react-icons/fa";
import Modal from '../../components/parts/Modal';
import { postData } from '../../lib/apiHandler';
import { FaSquarePhone } from "react-icons/fa6";

function LoadingPage() {
    const mainRef = useRef()
    const [isScrolled, setIsScrolled] = useState(false);
    const navigate = useNavigate()

    useEffect(() => {
        const handleScroll = () => {
        const element = mainRef.current;
        var top = element.getBoundingClientRect().top
        setIsScrolled(top < 0);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const [show, setShow] = useState(false)
    const [sel, setSel] = useState(0)

    useEffect(() => {
        if (show && sel === 0) {
            setSel(1)
        }
    }, [show])


    const selBut = [
        {
            h1: 'Cryptocurrency',
            text: ''
        },
        {
            h1: 'Forex',
            text: ''
        },
        {
            h1: 'Stock Trading',
            text: ""
        },
    ]

    const sendMsg = async (e) => {
        e.preventDefault()


        var formData = new FormData(e.target);
        var obj = {};
        formData.forEach((value, key) => obj[key] = value);

        const result = await postData('/contact', {
            ...obj
        })
        setShow(false)


    }



    const ref1 = useRef();
    const ref2 = useRef();
    const ref3 = useRef();

    const scrollToRef = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
    };


  return (
    <div className="flex flex-col min-h-screen">

        {/* MENU */}
        <div className={`fixed ${isScrolled ? 'shadow' : 'bg-white/80 '} backdrop-blur-sm top-0 w-full transition duration-300 ease-in-out z-20`}
            style={{background: isScrolled ? 'linear-gradient(83.51deg, #6A47C9 20.66%, rgba(166, 133, 246, 0.9551) 48.11%, rgba(94, 224, 212, 0.5838) 92.73%)' : ''}}
        >
            <div className='bod min-h-[72px] flex flex-row items-center justify-between'>
                <div onClick={() => navigate('/')} className={`cursor-pointer text-2xl font-semibold ${isScrolled ? 'text-white' : 'text-king'} flex flex-row space-x-1`}>
                    {/* <FaBtc className="text-3xl" /> */}
                    <span>BsmartSolutions</span>
                    <span className='text-sm relative font-bold'>LTD</span>
                </div>
                <div className='flex flex-row items-center space-x-6'>
                    <div className={`flex-row space-x-8 font-semibold text-lg ${isScrolled ? 'text-white' : ''} hidden md:flex`}>
                        <button onClick={() => {scrollToRef(ref1)}}>Recover</button>
                        <button onClick={() => {scrollToRef(ref2)}}>information</button>
                        <button onClick={() => {scrollToRef(ref3)}}>FAQ</button>
                    </div>
                    <button onClick={() => setShow(true)}  className={`btn3 py-2  font-semibold ${isScrolled ? 'bg-king text-white hover:bg-[#6A47C9]' : 'bg-[#6A47C9] hover:bg-king'}`}>
                        Get your money back
                    </button>
                </div>
            </div>
        </div>

        {/* HEADER */}
        <div className="" style={{background: 'linear-gradient(83.51deg, #6A47C9 20.66%, rgba(166, 133, 246, 0.9551) 48.11%, rgba(94, 224, 212, 0.5838) 92.73%)'}}>
            <div className='bod py-[20px] md:pt-[60px] md:pb-[20px]'>
                <div className='flex flex-row md:flex-row items-center relative'>
                    <div className='absolute hidden md:flex'>
                        <img src={`/icons2/group_45.png`} className='max-w-[300px]' />
                    </div>

                    <div className='w-full min-w-[340px] space-y-6 text-center mt-20 my-12  flex flex-col items-center z-10'>
                        <h1 className='text-king text-[40px] sm:text-[50px] font-bold leading-[65px]'>
                            Get your money back<br></br>
                            from a Bitcoin &<br>
                            </br> Cryptocurrency Scams
                        </h1>

                        <p className='text-[20px] sm:text-[22px] w-full max-w-[580px] text-white font-semibold'>
                            If you’ve been ripped off by scammers, get in touch and our team of experts will work to get your money back
                        </p>
                        <div className='py-2 space-y-2'>
                            <select className='inp' value={sel} onChange={(e) => setSel(Number(e.target.value))}>
                                <option value={0}>Choose a scam type</option>
                                <option value={1}>Cryptocurrency</option>
                                <option value={2}>Forex</option>
                                <option value={3}>Stock Trading</option>
                                {/* <option>Other scam</option> */}
                            </select>
                            <button className='btn34 px-[25px] py-2.5 text-xl font-semibold rounded-lg'  onClick={() => setShow(true)}>
                                Get a free consultation
                            </button>
                        </div>
                    </div>
                    <div className='absolute right-0'>
                        <img src={`/icons2/eth.png`} className='max-w-[300px]' />
                    </div>

                </div>
            </div>
        </div>

        <div ref={mainRef}>
            
            {/* SEC1 */}
            <div className='bg-[#f7f9fc]' ref={ref1}>
                <div className='bod py-[120px] space-y-8'>

                    <h1 className='text-king text-[36px] font-bold text-center'>How it works</h1>

                    <div className='flex items-center justify-center'>
                        <div className='w-full grid sm:grid-cols-2 gap-7 max-w-[800px]'>
                            <div className='con shadow-md p-5 space-y-2 flex flex-col'>
                                <h1 className='font-bold text-xl'>Review your case</h1>
                                <span className='text-gray-600'>Performing preliminary checks to assess whether the case can result in a substantial recovery, based on our experience.</span>
                            </div>
                            <div className='con shadow-md p-5 space-y-2 flex flex-col'>
                                <h1 className='font-bold text-xl'>Gather the evidence</h1>
                                <span className='text-gray-600'>Collecting all the information and documentation required to successfully pursue your case.</span>
                            </div>
                            <div className='con shadow-md p-5 space-y-2 flex flex-col'>
                                <h1 className='font-bold text-xl'>Confront the entities</h1>
                                <span className='text-gray-600'>Systematically confronting the relevant entities that have facilitated the illicit transfer of your wealth.</span>
                            </div>
                            <div className='con shadow-md p-5 space-y-2 flex flex-col'>
                                <h1 className='font-bold text-xl'>Get your money back</h1>
                                <span className='text-gray-600'>We take pride in our track record and assure you that we’ll go to great lengths to get your money back.</span>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>

            <div ref={ref2}>
                <div className='bod py-[100px] space-y-7 text-start'>
                    <div>
                        <h1 className='text-king text-[36px] font-bold'>Recover Your Lost Funds</h1>
                    </div>
                    <div className='max-w-[1000px]'>
                        <span className='text-gray-400 text-[22px] font-semibold'>No matter how hard governments try to regulate it, the Cryptocurrency industry is still alive and kicking. Since the topic is quite ambiguous to the general public, frauds use it to scam people out of their money, with what looks like a genuine investment opportunity. If you fell into a Cryptocurrency scam, please contact us now. Although it is extremely difficult to recover money from Crypto scams, we will give you our honest evaluation of your case, and if possible, we will give it our best effort to recover your money</span>
                    </div>
                    <button className='btn34 px-[25px] py-2.5 text-xl font-semibold rounded-lg'  onClick={() => setShow(true)}>
                        Get a free consultation
                    </button>
                </div>
            </div>


            <div>
                <div className='bod py-[100px] py-[80px] space-y-7 text-start'>
                    <div>
                        <h1 className='text-king text-[36px] font-bold'>Your money back guarantee</h1>
                    </div>
                    <div className='max-w-[1000px]'>
                        <span className='text-gray-400 text-[22px] font-semibold'>The fund recovery process can be a lengthy one and requires perseverance. Therefore it is vital that our clients are ready for it and trust us every step of the way. So if for any reason you are doubtful, you can ask for a full refund within the first 14 business days of the process.</span>
                    </div>
                    <button className='btn3 px-[25px] py-2.5 text-xl font-semibold text-king bg-white border border-king rounded-lg hover:bg-king hover:text-white'  onClick={() => navigate('/terms')}>
                        Read Terms & Conditions
                    </button>
                </div>
            </div>

            <div className='bg-[#a8a1f3] !text-center'>
                <div className='bod py-[100px] py-[80px] space-y-7 flex flex-col items-center'>
                    <div>
                        <h1 className='text-white text-[36px] font-bold'>Let's get your money back!</h1>
                    </div>
                    <div className='max-w-[700px] text-king'>
                        <span className='text-[22px] font-semibold'>If you've been ripped off by scammers, get in touch and our team of experts will work to get your money back</span>
                    </div>
                    <div className='py-2 space-y-2 w-full max-w-[400px]'>
                        <select className='inp' value={sel} onChange={(e) => setSel(Number(e.target.value))}>
                            <option value={0}>Choose a scam type</option>
                            <option value={1}>Cryptocurrency</option>
                            <option value={2}>Forex</option>
                            <option value={3}>Stock Trading</option>
                            {/* <option>Other scam</option> */}
                        </select>
                        <button className='btn34 px-[25px] py-2.5 text-xl font-semibold rounded-lg w-full'  onClick={() => setShow(true)}>
                            Get a free consultation
                        </button>
                    </div>
                </div>
            </div>





            <div className='' ref={ref3}> 
                <div className='bod py-[100px] text-start'>
                    <div className='flex flex-col space-y-4 max-w-[1000px]'>
                        <h1 className='text-king text-[36px] font-bold '>Crypto Scam FAQ</h1>
                        <div className='flex flex-col space-y-1'>
                            <Faq title='Can you get scammed on Bitcoin?' text='Scammers and fraudsters exist everywhere, and this is especially true when it comes to Bitcoin. As a brand new asset class, be very careful when it comes to opportunities in this investment space.' />
                            <Faq title='How can you spot a Bitcoin scammer?' text='Scammers and fraudsters exist everywhere, and this is especially true when it comes to Bitcoin. As a brand new asset class, be very careful when it comes to opportunities in this investment space.' />
                            <Faq title='Can you lose money on Bitcoin?' text='Scammers and fraudsters exist everywhere, and this is especially true when it comes to Bitcoin. As a brand new asset class, be very careful when it comes to opportunities in this investment space.' />
                            <Faq title='How can we help you get money back?' text='Scammers and fraudsters exist everywhere, and this is especially true when it comes to Bitcoin. As a brand new asset class, be very careful when it comes to opportunities in this investment space.' />
                        </div>
                    </div>
                </div>
            </div>


            <div className='bg-[#8b6ae1]'>
                <div className='bod py-[60px] md:py-[80px] flex flex-col md:flex-row  flex justify-between items-start md:items-center space-y-4 md:space-y-0 text-white'>

                    <div className='space-y-1'>
                        <div className={`text-3xl font-bold flex flex-row space-x-1`}>
                            <span>BsmartSolutions</span>
                            <span className='text-sm relative font-bold'>LTD</span>
                        </div>
                        <div className='flex flex-row items-center space-x-1'>
                            <FaSquarePhone className='text-[22px]' />
                            <span>
                                +18662507178
                            </span>
                        </div>
                    </div>



                    <div className='flex flex-col items-end w-full'>
                        <span className='font-semibold'>Copyright © 2024 BsmartSolutions.LTD All rights reserved.</span>
                        <div className='flex flex-row space-x-2'>
                            <Link to='/terms' className='font-semibold hover:underline'>Terms of service</Link>
                            <span>/</span>
                            <Link to='/policy' className='font-semibold hover:underline'>Privacy policy </Link>
                        </div>
                    </div>

                </div>
            </div>


        </div>
        <Modal isCreateOffer={show} setIsCreateOffer={setShow}>

            <form className='flex w-full items-center justify-center' onSubmit={sendMsg} autoComplete='off'>
                <div className='flex flex-col w-full max-w-[800px] space-y-6'>

                    <div className='flex flex-row space-x-3'>
                        <div className='w-full'>
                            <span className='text-gray-600'>
                                Full name
                            </span>
                            <input className='inp inp23' name='name' type='text' required />
                        </div>
                        <div className='w-full'>
                            <span className='text-gray-600'>
                                Email
                            </span>
                            <input className='inp inp23' name='email' type='email' required />
                        </div>
                    </div> 

                    <div className='flex flex-row justify-between space-x-5'>
                        {
                            selBut.map((item, i) => (
                                <div key={i} onClick={() => setSel(i+1)} className={`flex flex-col space-y-1 con w-full cursor-pointer ${sel === i+1 ? 'bg-[#6A47C9] text-white shadow-lg [&>span]:text-gray-200' : '[&>span]:text-gray-700'}`}>
                                    <h2 className='text-lg font-semibold leading-5'>{item.h1}</h2>
                                    <span className=''>{item.text}</span>
                                </div>
                            ))
                        }
                    </div>

                    <div className='space-y-1'>
                        {/* <span className='text-gray-600'>
                                Email
                        </span> */}
                        <textarea name='message' className='inp inp23 h-[120px] text-[18px]' placeholder='Tell us your needs, and we’ll tailor our support to fit.' required>

                        </textarea>
                    </div>

                    <div className='flex flex-row items-end justify-end'>
                        <button type='submit' className='btn btn3 bg-[#6A47C9] hover:bg-king text-white px-7 text-[22px] h-[47px]'>Send</button>
                    </div>
                </div>
            </form>





        </Modal>
    </div>
  );
}

export default LoadingPage;







function Faq({title, text}) {
    const [show, setShow] = useState(false)
    return (
        <div className='cursor-pointer border-b !mb-5 pb-2' onClick={() => setShow(prev => !prev)}>
            <div className='flex flex-row justify-between'>
                <h2 className='text-king text-[26px] font-semibold'>{title}</h2>
                <button className='px-4'>
                    {
                        show ? (
                            <FaChevronUp />
                            ) : (
                            <FaChevronDown />
                        )
                    }
                </button>
            </div>
            {
                show && (
                    <p className='text-[20px] mt-2 mb-1'>
                        {text}
                    </p>
                )
            }
        </div>
    )
}
