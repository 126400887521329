
const Modal = ({children, isCreateOffer, setIsCreateOffer}) => {
    return (
        <div 
            className={`${!isCreateOffer && 'hidden' } !mt-0 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full inset-0 max-h-full bg-[#b5b4b470] flex items-center`}
            onClick={() => setIsCreateOffer(false)}
        >
            <div onClick={(e) => e.stopPropagation()} className="relative bg-white rounded-lg shadow w-full max-w-[600px] p-4">
                {children}
            </div>
        </div>
    )
}

export default Modal
